<template>
	<div class="about container">
		<h4 class="mb-3 pb-3">About the IMMUcan SingleCell RNAseq Database</h4>
		<ul class="nav nav-tabs mt-3">
			<li class="nav-item">
				<button class="nav-link" :class="(nav==='guide')?'active':''" @click="nav='guide'">User guide</button>
			</li>
			<li class="nav-item" v-if="diseases.length">
				<button class="nav-link"  :class="(nav==='diseases')?'active':''"  @click="nav='diseases'">List of diseases</button>
			</li>
			<li class="nav-item" v-if="cells.length">
				<button class="nav-link"  :class="(nav==='cells')?'active':''" @click="nav='cells'">List of cell enrichments</button>
			</li>
			<li class="nav-item">
				<button class="nav-link"  :class="(nav==='stats')?'active':''" @click="nav='stats'">Database statistics</button>
			</li>
		</ul>
		<div :style="`visibility:${contactVisibility}; height: ${contactHeight}`">

			<h1 id="toc_0" class = "mt-3">User guide</h1>


			<h2 id="toc_0.1">Where to find us</h2>
			
			<p class="lead">Visit <a href="https://immucanscdb.vital-it.ch/" class="uri">https://immucanscdb.vital-it.ch/</a> for the public IMMUcan
scDB. <br />Visit <a href="https://github.com/ImmucanWP7/immucan-scdb" class="uri">https://github.com/ImmucanWP7/immucan-scdb</a> for
scProcessor code.</p>
			<template>
				<div class='lead mt-2'><p><b>For any remark on the database content</b>, please contact:</p>
					<ul>
						<li>Vassili Soumelis (<span id="vassili-protector"></span>)</li>
						<li>Helge Roider (<span id="helge-protector"></span>)</li>
					</ul>
				</div>
				<div class='lead'><p><b>For any remark or bug report on the functionalities of the web application</b>, please contact:</p>
					<ul>
						<li>Robin Liechti (<span id="robin-protector"></span>)</li>
					</ul>
				</div>
			</template>
			
			<h2 id="toc_1">1. Searching studies</h2>

			<h3 id="toc_2">Based on metadata</h3>

			<p>The startpage contains a list of all the studies incorporated in IMMUcan scDB. Important information such as <strong>tissue, cancer type, treatment type, cell enrichment and cell amount</strong> are visualized in columns and are searchable. </p>

			<p><img src="../assets/guide/StartPage_highRes.png" alt="Start Page" class="w-100"></p>

			<p>It is also possible to search for all the information gathered by clicking <strong>advanced filters</strong>. Here is an overview of all the information gathered.</p>

			<p><img src="../assets/guide/immucan_overview.png" alt="IMMUcan Overview" class="w-100"></p>

			<h3 id="toc_3">Based on a cell type</h3>

			<p>All cells in the IMMUcan scDB are annotation first with a supervised learning model called <a href="https://genomebiology.biomedcentral.com/articles/10.1186/s13059-019-1795-z">CHETAH</a>. Based on this annotation, we further specified three ontology levels: major, immune and minor. These annotations are linked to the <a href="https://www.ebi.ac.uk/ols/ontologies/cl">EBI cell ontology</a>. If available we also specify the original authors annotations. </p>

			<p><img src="../assets/guide/immucan_cellOntology.png" alt="IMMUcan cell Ontology" class="w-100"></p>

			<p>On the right top you can search for your cell type of interest. The result will be a list of all datasets that contain your cell type ranked by the number of cells.</p>

			<p><img src="../assets/guide/StartPage_searchCellType.png" alt="search cell type" class="w-100"></p>

			<h3 id="toc_4">Based on a gene of interest</h3>

			<p>You can also search for gene of interest. We performed an entropy-based ranking system to prioritize datasets where your gene of interest is most exclusive to a certain cell type. The result is a heatmap with the average expression of your gene per cell type over all studies. Studies are ranked according to importance from top to bottom.</p>

			<p><img src="../assets/guide/StartPage_searchGene.png" alt="search gene" class="w-100"></p>

			<h2 id="toc_5">2. Exploring a dataset</h2>

			<h3 id="toc_6">Tabs</h3>

			<p>Every dataset contains three tabs with specific information and an interface.</p>

			<ul>
				<li><strong>Info</strong>: general metadata collected from the publication</li>
				<li><strong>UMAP plot</strong>: visualization of cell types and genes</li>
				<li><strong>Gene X vs Gene Y comparison</strong>: analyzing correlation between two genes</li>
			</ul>

			<h3 id="toc_7">Study information</h3>

			<p>You can find all the information that we gathered of the dataset in the info tab.</p>

			<p><img src="../assets/guide/Infos.png" alt="Study information" class="w-100"></p>

			<h3 id="toc_8">UMAP plot</h3>

			<p>When clicking on the desired dataset it will open an interface to that dataset with two plots vizualizing the essence of that dataset. One plot on the left is a UMAP plot that vizualizes the cells in reduced dimensionality. You can color the cells by important metadata information in that study such as all the different annotations. On the right is a barplot with that summarizes the cell type fractions per patient over different biopsies, samples or treatment.</p>

			<p>Below are two tables. One called <strong>Average gene expression</strong> with all the gene information, the average expression of the gene per cluster and links to databases like uniprot. Another table is called <strong>Differential gene expression</strong> and contains all the information on differential gene calculations between cell types in the annotion level of choice.</p>

			<p><img src="../assets/guide/UMAP.png" alt="UMAP" class="w-100"></p>

			<p>Here you can search for your gene of interest inside one dataset. When clicking on your gene of interest in the table it will vizualize two additional plots. The plot on the left is the gene expression vizualized in the UMAP representation. On the right is a violin and boxplot with the expression of the gene per cell type. Below the graph you can find pie graphs that show how many cells per cell type actually epxress the gene.</p>

			<p><img src="../assets/guide/UMAP_gene.png" alt="UMAP gene" class="w-100"></p>

			<h3 id="toc_9">Gene X vs Gene Y comparison</h3>

			<p>A last tab provides you information on correlation between two genes. Here you can add two genes of interest and observe a scatterplot of the expression of those genes in every cell. Cells are visualized according to the annotation that is selected. In the legend there is also a pearson correlation given per annotation together with the total number of cells.</p>

			<p>Some summary statistics are calculated on the correlation that you can find below the scatterplot. For each cell type, Venn diagrams counting the number of cells expressing gene X and/or gene Y. Pie charts represent the proportion of cells expressing one of the two genes.</p>

			<p><img src="../assets/guide/Gene_X_Y.png" alt="Gene X vs Y" class="w-100"></p>

			<h2 id="toc_10">Further information</h2>

			<p>The IMMUcan scDB has been developed by the <a href="https://immucan.eu/">IMMUcan consortium</a> and is a collaboration between Bayer AG, INSERM, Sanofi and the <a href="https://www.sib.swiss/research-infrastructure/competence-centers/vital-it">SIB Swiss Insitute of Bioinformatics</a>.</p>

		</div>
		<template v-if="nav==='diseases'">
			<b-table :items="diseases" small>
			<template v-slot:cell(doi_id)="data">
				<a :href="`https://disease-ontology.org/term/${data.value}`" target="_blank" v-if="data.value.indexOf('DOID')>-1">{{data.value}}<span class="ml-2"><v-icon name="external-link-alt" scale="0.8" /></span></a>
				<span v-else>{{data.value}}</span>
			</template>
		</b-table>
	</template>
	<template v-if="nav==='cells'">
		<b-table :items="cells"  small></b-table>
	</template>
	<template v-if="nav==='stats'">
		<db-statistics></db-statistics>
	</template>
</div>
</template>

<script>
import '@/assets/email-protector.js'
import { HTTP } from '@/router/http'
import dbStatistics from '@/components/dbStatistics.vue'
export default {
	name: 'about',
	components: { dbStatistics },
	data () {
		return {
			nav: 'guide',
			diseases: [],
			cells: []
		}
	},
	computed: {
		contactVisibility () {
			return (this.nav==='guide') ? 'visible' : 'hidden'
		},
		contactHeight () {
			return (this.nav==='guide') ? 'auto' : '0px'
		}
	},
	mounted () {
		EmailProtector.setGlobalConfig({ code: 9 });
		HTTP.get('/cvs').then(res => {
			this.diseases = res.data.diseases
			this.cells = res.data.cells
		})
		if (this.$route.query.view){
			this.nav = this.$route.query.view
		}
		else{
			
			document.getElementById('vassili-protector').innerHTML=""
			document.getElementById('helge-protector').innerHTML=""
			document.getElementById('robin-protector').innerHTML=""

			window.dispatchEvent(new Event('load'));			
		}
		if (document.getElementById('vassili-protector').innerHTML === ""){
			EmailProtector.append('vassili-protector', 'mrjjzcz.jfldvczj@rgyg.wi');	
		}
		if (document.getElementById('helge-protector').innerHTML === ""){
			EmailProtector.append('helge-protector', 'yvcxv.ifzuvi@srpvi.tfd');	
		}
		if (document.getElementById('robin-protector').innerHTML === ""){
			EmailProtector.append('robin-protector', 'ifsze.czvtykz@jzs.jnzjj');	
		}
	},
	watch: {
		nav (n,o) {
			if (n !== this.$route.query.view){
				this.$router.push({query: {view: n}})	
			}			
			if (n === 'guide'){
				document.getElementById('vassili-protector').innerHTML=""
				document.getElementById('helge-protector').innerHTML=""
				document.getElementById('robin-protector').innerHTML=""
				window.dispatchEvent(new Event('load'));
				
			}
		}
	}
}
</script>

<style scoped>
h1, h2, h3, h4 {
	margin-top: 1.2rem;
	margin-bottom: 1.5rem;
}
h1 {
	font-size: 1.8rem;
}
h2 {
	font-size: 1.6rem;
}
h3 {
	font-size: 1.4rem;
}
h4 {
	font-size: 1.2rem;
}
</style>
