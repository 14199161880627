<template>
<div class="dbStatistics pt-4">
	<h4 class="py-4">Database statistics</h4>
	<chart :options="chartOptions" :style="`height: 600px; width: 80%;`" :autoresize="true"></chart>
</div>
</template>
<script>
import { HTTP } from '@/router/http.js'
export default {
	name: 'dbStatistics',
	data () {
		return {
			stats: {
				total: {},
				h5ad: {}
			},
			chartOptions: {
				title: {
					text: 'Total number of datasets'
				},
				tooltip: {
					trigger: 'axis'
				},
		    legend: {
		        data: ['Total number of datasets','Number of datasets with integrated data']
		    },
		    grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
		    },
				xAxis: {
					type: 'time',
					splitLine: {show: false}
				},
				yAxis: {
					splitLine: {show: false}
				},
				series: [{
					name: 'Total number of datasets',
					type: 'line',
					showSymbol: false,
					data: []
				}, {
					name: 'Number of datasets with integrated data',
					type: 'line',
					showSymbol: false,
					data: [],
				}]
			}
		}
	},
	mounted () {
		HTTP.get("/stats").then(res => {
			this.stats = res.data
			this.chartOptions.series[0].data = res.data.total
			this.chartOptions.series[1].data = res.data.h5ad
		})
	}
	
}
</script>